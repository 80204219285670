import React, { useState, useEffect } from "react"
import { LocaleContext } from "./layout"
import useTranslation from "../../mlang/useTranslation"
import MLink from "../../mlang/mlink"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { CSSTransition } from "react-transition-group"


const Navigation = ({ pathname }) => {
  const { file: { logo } } = useStaticQuery(graphql`
  {
    file(name: {eq: "infra-logo"}) {
      logo: childImageSharp {
        fluid(quality: 100) {
          originalName,
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`)

  const langsNames = {
    uk: "Українська",
    ru: "Русский",
    en: "English",
  }

  const { locales, currentLocale } = React.useContext(LocaleContext)
  const { navigation } = useTranslation('navigation')
  const [activeMenu, setActiveMenu] = useState('nav')
  const [menuIsActive, setMenuIsActive] = useState(false)
  const [menuHeight, setMenuHeight] = useState(0)
  const [isWide, setIsWide] = useState(false)

  const calcHeigth = (el) => {
    const height = el.offsetHeight
    setMenuHeight(height)
  }

  const isClient = typeof window === 'object';

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    if (window.innerWidth >= 768) {
      setMenuIsActive(true)
      setIsWide(true)
    }

    function handleResize() {
      setMenuIsActive(window.innerWidth >= 768)
      setIsWide(window.innerWidth >= 768)
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }); // Empty array ensures that effect is only run on mount and unmount

  const menuLinks = <ul className="menu__links">
    <li><MLink className="menu__link" to="/">{navigation[0]}</MLink></li>
    <li><MLink className="menu__link" to="/#equipments">{navigation[1]}</MLink></li>
    <li><MLink className="menu__link" to="/components">{navigation[2]}</MLink></li>
    <li><MLink className="menu__link" to="/#services">{navigation[3]}</MLink></li>
    <li><MLink className="menu__link" to="#contacts">{navigation[4]}</MLink></li>
    <li className="menu__language">
      <a className="menu__link menu__language-btn" onClick={() => {
        if (activeMenu === 'nav') {
          setActiveMenu('lang')
        } else {
          setActiveMenu('nav')
        }
      }}>
        <div className="menu__language-btn-shifter"></div>
        {langsNames[currentLocale]}
        {isWide ? <i className="fas fa-caret-down"></i> :
          <div className="menu__language-btn-shifter"><i className="fas fa-chevron-right"></i></div>
        }
      </a>
      {isWide ?
        <div className="menu__language-dropdown">
          <CSSTransition
            in={activeMenu === 'lang'}
            mountOnEnter
            unmountOnExit
            timeout={300}
            classNames="menu__language-dropdown"

          >
            <div className="menu__language-dropdown-content">
              {locales.filter(lang => lang !== currentLocale)
                .map(lang => (
                  <MLink
                    key={lang}
                    locale={lang}
                    className="menu__link"
                  >
                    {langsNames[lang]}
                  </MLink>
                ))
              }
            </div>
          </CSSTransition>
        </div>
        :
        null
      }
    </li>
  </ul>

  const menuLangs = <ul className="menu__links languages-menu">
    {locales.filter(lang => lang !== currentLocale)
      .map(lang => (
        <li key={lang}>
          <MLink
            locale={lang}
            className="menu__link"
          > {langsNames[lang]} </MLink>
        </li>
      ))
    }
    {isWide ? null : <li>
      <a className="menu__link menu__language-btn" onClick={() => setActiveMenu('nav')}>
        {isWide ? null :
          <div className="menu__language-btn-shifter"><i className="fas fa-chevron-left"></i></div>}
        {langsNames[currentLocale]}
        <div className="menu__language-btn-shifter"></div>
      </a>
    </li>
    }
  </ul>

  return (
    <header className="header">
      <nav className="menu">
        <div className="menu__control">
          <div className="menu__logo">
            <MLink to="/">
              <Img
                className="image"
                alt={logo.fluid.originalName}
                fluid={logo.fluid}
              />
            </MLink>
          </div>
          <div
            className={`menu__btn${menuIsActive ? ' open' : ''}`}
            onClick={() => {
              setMenuIsActive(!menuIsActive)
              setActiveMenu('nav')
            }}
          >
            <div className="menu__btn-burger"></div>
          </div>
        </div>
        {isWide ?
          <div className="menu__dropdown">
            {menuLinks}
          </div>
          :
          <div className="dropdown-wrapper">
            <div
              className={`menu__dropdown${menuIsActive ? ' show' : ''}`}
              style={{ height: menuHeight }}
            >
              <CSSTransition
                in={menuIsActive}
                mountOnEnter
                unmountOnExit
                timeout={500}
                classNames="menu__dropdown"
                onEnter={calcHeigth}
                onExit={calcHeigth}
              >
                <div className="menu__content">
                  <CSSTransition
                    in={menuIsActive && activeMenu === 'nav'}
                    mountOnEnter
                    unmountOnExit
                    timeout={500}
                    classNames="menu__links"
                    onEnter={calcHeigth}
                  >
                    {menuLinks}
                  </CSSTransition>
                  <CSSTransition
                    in={menuIsActive && activeMenu === 'lang'}
                    unmountOnExit
                    timeout={500}
                    classNames="menu__langs"
                    onEnter={calcHeigth}
                  >
                    {menuLangs}
                  </CSSTransition>
                </div>
              </CSSTransition>
            </div>
            <CSSTransition
              in={menuIsActive}
              mountOnEnter
              unmountOnExit
              timeout={500}
              classNames="menu__dropdown-backdrop"
            >
              <div
                className="menu__backdrop"
                onClick={() => {
                  setMenuIsActive(!menuIsActive)
                  setActiveMenu('nav')
                }}
              ></div>
            </CSSTransition>
          </div>
        }
      </nav>
    </header>
  )
}


export default Navigation