import React from "react"
import Iframe from "react-iframe"

const Footer = () => (
  <footer className="footer" id="contacts">
    <div className="contacts">
      <div className="contacts__item">
        <i className="fas fa-phone-square-alt fa-2x phone__icon"></i>
        <br />
        <span className="phone__number">+380-63-270-52-06</span>
</div>
      <div className="contacts__item">
        <a
          className="facebook-link"
          href="https://www.facebook.com/infracentre/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-square fa-2x"></i>
          <br />
          <span>www.facebook.com/infracentre</span>
        </a>
      </div>
      <div className="contacts__item">
        <i className="fas fa-envelope fa-2x"></i>
        <br />
        <span>infracentre@gmail.com</span>
        <br />
        <span>office@infra.com.ua</span>
      </div>
    </div>
    <div className="map">
      <Iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2594.2406242358456!2d32.0254277156951!3d49.44216897934894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d14c63e489e98f%3A0x5413b10756b24017!2z0J7QntCeICLQmNC90YTRgNCwLdGG0LXQvdGC0YAi!5e0!3m2!1suk!2sua!4v1592806520742!5m2!1suk!2sua"
        width="100%"
        height="100%"
        frameborder="0"
        style={{ border: "0" }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      />
    </div>
    <div className="rights">&copy; 2020 Infra-Center. All Rights Reserved.</div>
  </footer>
)

export default Footer
