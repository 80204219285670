import React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import "../../styles/index.scss"

import Header from "./header"
import Footer from "./footer"

const LocaleContext = React.createContext()

const Layout = ({ children, context, location }) => (
  <LocaleContext.Provider value={context}>
    <Helmet>
      <script
        src="https://kit.fontawesome.com/3d968a6096.js"
        crossorigin="anonymous"
      ></script>
      <script src={withPrefix("scroll.js")} type="text/babel" />
    </Helmet>
    <Header {...location} />
    <main className="content">{children}</main>
    <Footer />
  </LocaleContext.Provider>
)

export { LocaleContext }
export default Layout
