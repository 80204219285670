import React from "react"
import Layout from "./layout"

const withLayout = (WrappedComponent) => {
  return (props) => {
    const { pageContext: { mLang }, location } = props

    return (
      <Layout context={mLang} location={location}>
        <WrappedComponent {...props}/>
      </Layout>
    )
  }   
}


export default withLayout