import React from "react"
import { Link } from "gatsby"
import { LocaleContext } from "../components/layout/layout"

const MLink = ({ to, locale, ...props }) => {
  const { currentLocale, defaultLocale, currentPath } = React.useContext(LocaleContext)
  let path = ''

  if (locale) {
    locale = locale === defaultLocale ? '' : `/${locale}`
    path = locale + currentPath
  } else {
    locale = currentLocale === defaultLocale ? '' : `/${currentLocale}`
    path = locale + (to.match('^\\/') ? '' : currentPath) + to
  }

  return <Link {...props} to={path} />
}

export default MLink