import React from "react"
import { LocaleContext } from "../components/layout/layout"

const useTranslation = (value) => {
  const { currentLocale } = React.useContext(LocaleContext)

  let translation = {}

  if (Array.isArray(value)) {
    for (let equipment of value) {
      try {
        let { title, purpose } = require(`../mlang/translations/${currentLocale}/${equipment}.json`)
        translation[equipment] = { title, purpose }
      } catch (err) {
        translation[equipment] = { title: 'No translation available', purpose: 'No translation available' }
      }
    }
  } else {
    try {
      translation = require(`../mlang/translations/${currentLocale}/${value}.json`)
    } catch (err) {
      translation = { title: 'No translation available' }
    }
  }

  String.prototype.getHTML = function () {
    return <span dangerouslySetInnerHTML={{ __html: this }} />
  }

  return translation
}


export default useTranslation